// Ultility Classes

// Hide on mediaquery
.hide-on-sm
{
    @media (max-width: 40em)
    {
        display: none!important;
    }
}
.show-on-sm
{
    display:block;
    @media (min-width: 40em)
    {
        display: none;
    }
}
.hide-on-md
{
    @media (max-width: 52em) and (min-width: 40em)
    {
        display: none!important;
    }
}
.show-on-md
{
    display:block;
    @media (min-width: 64em)
    {
        display: none;
    }
}
.hide-on-lg
{
    @media (max-width: 75em) and (min-width: 52em)
    {
        display: none!important;
    }
}
.show-on-lg
{
    display:block;
    @media (min-width: 75em)
    {
        display: none;
    }
}
.hide-on-xl
{
    @media (min-width: 75em)
    {
        display: none!important;
    }
}

.align-center
{
    text-align: center;
}
.align-left
{
    text-align: left;
}
.align-right
{
    text-align: right;
}

.no-padding
{
    padding: 0;
}

.relative
{
    position: relative;
}

.tel-desktop
{
    @media (max-width: 40em)
    {
        display: none;
    }
}

.tel-mobile
{
    @media (min-width: 40em)
    {
        display: none;
    }
}
