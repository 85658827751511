// Products

.c-product-item {
    padding: 0 0 3em;
	@media (min-width: 40em) {
		display: flex;
        justify-content: flex-start;
        align-items: flex-start;
	}
}

.c-product-item__content {
    // display: block;
    h3 {
        margin-top: 0;
    }
	@media (min-width: 40em) {
		padding-left: 2em;
	}
}

.c-product-item__img {
    display: block;
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
    &::after {
        display: none!important;
    }
    img {
        display: block;
        max-width: 100%;
        transition: transform 0.2s ease-out;
        @media (min-width: 40em) {
            max-width: 200px;
        }
    }
    margin-bottom: 1em;
}
