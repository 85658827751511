// Registartion Page

*:focus {
    outline: none;
}

// Registration Contact Information
.registration-contact
{
    &-left
    {
        padding-left: 48px;
    }
    h3
    {
        margin-bottom: 8px;
    }
    p
    {
        line-height: (22 / $base-font-size) + rem;
        margin-bottom: 28px;
    }
    address
    {
        font-size: (18 / $base-font-size) + rem;
        line-height: (30 / $base-font-size) + rem;
        color: $blackprimary;
    }
    a
    {
        text-decoration: none;
        font-size: 1rem;
        line-height: (22 / $base-font-size) + rem;
        @media (min-width: 40.05em)
        {
            font-size: (18 / $base-font-size) + rem;
            line-height: (30 / $base-font-size) + rem;
        }
        color: $blackprimary;
    }
    phone:first-of-type
    {
        margin-right: 14px;
    }
}


.form-submit
{
    text-align: left;
    padding: 0 34px;
}
// Registration Forms Download
.reg-forms
{
    background-color: $lightgray;
    padding: 20px 25px 30px;
    @media (min-width: 40.05em)
    {
        padding: 10px 15px 30px;
    }
    @media (min-width: 64em)
    {
        &::before
        {
            content: '';
            border-right: 232px solid;
            left: -232px;
            border-right-color: inherit;
            display: block;
            border-top: 0;
            border-bottom: 260px solid transparent;
            position: absolute;
            bottom: 0;
            color: #f0f0f0;
        }
    }
    @media (min-width: 75em)
    {
        &::before
        {
            content: '';
            border-right: 232px solid;
            left: -232px;
            border-right-color: inherit;
            display: block;
            border-top: 0;
            border-bottom: 232px solid transparent;
            position: absolute;
            bottom: 0;
            color: #f0f0f0;
        }
    }
    @media (max-width: 64em)
    {
        margin-top: 50px;
    }
    &--header
    {
        text-align: center;
        padding-bottom: 18px;
        h2
        {
            font-size: (40 / $base-font-size)+rem;
            line-height: (40 / $base-font-size)+rem;
            font-family: $font1;
            color: $primary;
            margin: 12px 0 0 0;
        }
    }
    &--links
    {
        border-top: 4px solid white;
        display: block;
        ul
        {
            padding-left: 0;
            margin: 0;
        }
        li
        {
            display: inline-block;
            text-align: center;
            padding-top: 20px;
            padding-left: 18px;
            padding-right: 18px;
            width: 49%;

            @media (min-width: 75em)
            {
                width: 49.4%;
            }
            &:first-of-type
            {
                border-right: 4px solid white;
            }
        }
        a
        {
            color: $primary!important;
            text-decoration: none;
            font-size: 14px;
            font-family: $font5;
            font-weight: bold;
            text-transform: uppercase;
        }
        span
        {
            display: block;
        }
    }

    .icon-pdf
    {
        background-image: url('../images/pdf.png');
        height: 66px;
        width: 65px;
        display: block;
        margin: 0 auto 4px;
    }
}

// Registration Form styles
.form-wrapper
{
    &.form-wrapper--reg
    {
        background-color: $lightgray;
        fieldset
        {
            margin: 50px 0;
        }
    }

    .item-lg-10
    {
        @media (min-width: 40em)
        {
            padding-left: 30px;
        }
    }
    fieldset
    {
        border: none;
        padding: 0 0;
        position: relative;
    }
    legend
    {
        display: inline-block;
        width: 100%;
        padding-bottom: 16px;
        border-bottom: 3px solid #c1c1c1;
        margin-bottom: 32px;
        h2
        {
            margin: 0;
            font-family: $font1;
            font-size: (40 / $base-font-size)+rem;
            line-height: (40 / $base-font-size)+rem;
            color: $primary;
        }
    }

    .field-group
    {
        display: block;
        position: relative;
        margin-bottom: 24px;


        // // // // // // // // // //

        input:required, textarea:required
        {
            border: 1px solid #c1c1c1;
        	// background: $lightgray url(images/red_asterisk.png) no-repeat 98% center;
        }
        input:required:valid, textarea:required:valid
        {
        	background: $lightgray url(images/valid.png) no-repeat 98% center;
        	box-shadow: 0 0 5px #5cd053;
        	border-color: #28921f;
        }
        input:focus:invalid, textarea:focus:invalid
        {
        	background: $lightgray url(images/invalid.png) no-repeat 98% center;
        	box-shadow: 0 0 5px #d45252;
        	border-color: #b03535
        }

        /* === Form hints === */
        .form_hint
        {
        	background: #d45252;
        	border-radius: 3px 3px 3px 3px;
        	color: white;
        	margin-left: 14px;
        	padding-left: 14px;
        	padding-right: 14px;
        	line-height: 42px;
        	z-index: 999; /* hints stay above all other elements */
        	position: absolute; /* allows proper formatting if hint is two lines */
        	display: none;
            @media (min-width: 40em)
            {
                left: 360px;
            }
        }
        // .form_hint::before
        // {
        // 	content: "\25C0";
        // 	color:#d45252;
        // 	position: absolute;
        // 	top:1px;
        // 	left:-6px;
        // }
        input:focus + .form_hint
        {
            display: block;
        }
        @media (min-width: 40em)
        {
            input:focus + .form_hint
            {
                display: inline;
            }
        }
        input:required:valid + .form_hint
        {
            background: #28921f;
        }
        input:required:valid + .form_hint::before
        {
            color:#28921f;
        }

        // // // // // // // // // //


        .field-label label
        {
            line-height: 42px;
            font-size: 18px;
            font-weight: 300;
            color: $darkgray;
            font-family: $font3;
            letter-spacing: -0.5px;
        }

        .error
        {
            radio, input
            {
                border-color: $primary!important;
            }
        }

        input[type=text], input[type=email] {
            font-size: 18px;
            font-weight: normal;
            padding: 10px 14px;
            background: none;
            border: 1px solid #c1c1c1;
            border-radius: 0;
            @media (min-width: 40em)
            {
                // position: absolute;
            }
        }
        textarea
        {
            background: none;
            border: 1px solid $blackprimary;
            width: 100%;
            @media (max-width: 40em)
            {
                width: 100%;
            }
            @media (min-width: 40em)
            {
                width: 320px;
            }
            @media (max-width: 64em)
            {
                width: 100%;
            }
        }
        &--text
        {
            input[type=text], input[type=email] {
                @media (max-width: 40em)
                {
                    width: 100%;
                }
                @media (min-width: 40em)
                {
                    width: 320px;
                }
                @media (max-width: 64em)
                {
                    width: 100%;
                }
            }
        }

        &--upload
        {
            input[type=file] {
                // width: 100%;
                // padding: 10px;
                // @media (min-width: 40em)
                // {
                //     min-width: 320px;
                // }
            }
        }

        &--phone
        {
            input{
                @include placeholder {
                    font-size: 18px;
                    font-weight: normal;
                    letter-spacing: 2px;
                    color: $blackprimary;
        		}
            }
            input:nth-of-type(1)
            {
                width: 62px;
            }
            input:nth-of-type(2)
            {
                width: 62px;
            }
            input:nth-of-type(3)
            {
                width: 73px;
            }

        }

        &--checkbox
        {
            label
            {
                display: block;
                position: relative;
                font-weight: 300;
                font-size: 1em;
                padding: 7px 10px 0px 34px;
                margin: 0 auto 7px;
                min-height: 20px;
                z-index: 9;
                cursor: pointer;
                -webkit-transition: all 0.25s linear;
            }
            input[type=checkbox]{
                position: absolute;
                visibility: hidden;
            }
            .check
            {
                display: block;
                position: absolute;
                border: 2px solid #c1c1c1;
                border-radius: 1px;
                height: 20px;
                width: 20px;
                top: 5px;
                left: 0;
                z-index: 5;
                transition: border .25s linear;
                -webkit-transition: border .25s linear;
            }
            input[type=checkbox]:checked ~ .check {
                border: 10px solid #c1c1c1;
            }

            input[type=checkbox]:checked ~ .check::before{
                background: #c1c1c1;
            }

            input[type=checkbox]:checked ~ label{
                color: #c1c1c1;
            }
        }

        &--radio
        {
            .radio--input
            {
                &__space
                {
                    padding-left: 30px;
                    padding-top: 8px;
                }
                &__boater
                {
                    label
                    {
                        display: inline-block!important;
                    }
                }
                label
                {
                    display: block;
                    position: relative;
                    color: #414244;
                    font-family: $font3;
                    font-size: 1em;
                    padding: 7px 10px 0px 34px;
                    margin: 0 auto 7px;
                    min-height: 20px;
                    z-index: 9;
                    cursor: pointer;
                    -webkit-transition: all 0.25s linear;
                }
                input[type=radio]{
                    position: absolute;
                    visibility: hidden;
                }
                .radio
                {
                    display: block;
                    position: absolute;
                    border: 2px solid #c1c1c1;
                    border-radius: 100%;
                    height: 20px;
                    width: 20px;
                    top: 5px;
                    left: 0;
                    z-index: 5;
                    transition: border .25s linear;
                    -webkit-transition: border .25s linear;
                }
                input[type=radio]:checked ~ .radio {
                    border: 10px solid $blackprimary;
                }

                input[type=radio]:checked ~ .radio::before{
                    background: $blackprimary;
                }

                input[type=radio]:checked ~ label{
                    color: $blackprimary;
                }
            }
        }
    }

    &.field-spacing
    {
        margin: 48px 0;
        .field-group
        {
            &--text
            {
                input[type=text], input[type=email] {
                    @media (min-width: 40em)
                    {
                        // left: 228px;
                    }
                }
            }
        }
    }

    .photo-upload
    {

        input
        {
            width: 0.1px;
        	height: 0.1px;
        	opacity: 0;
        	overflow: hidden;
        	position: absolute;
        	z-index: -1;
        }

        input + label {
            max-width: 100%;
            font-size: 1.25rem;
            /* 20px */
            font-weight: 700;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            display: inline-block;
            overflow: hidden;
            padding: 0.625rem 1.25rem;
            /* 10px 20px */
        }

        input + label {
            color: $blackprimary;
        }

        input + label {
            border: 1px solid $blackprimary;
            background-color: $white;
            padding: 0;
        }

        input:focus + label,
        input.has-focus + label,
        input + label:hover {
            border-color: $primary;
        }

        input + label span,
        input + label strong {
            padding: 0.625rem 1.25rem;
            /* 10px 20px */
        }

        input + label span {
            width: 200px;
            min-height: 2em;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            vertical-align: top;
        }

        input+ label svg {
            width: 1em;
            height: 1em;
            vertical-align: middle;
            fill: currentColor;
            margin-top: -0.25em;
            /* 4px */
            margin-right: 0.25em;
            /* 4px */
            color: $white
        }
        input + label strong {
            height: 100%;
            color: $white;
            background-color: $primary;
            display: inline-block;
        }

        input:focus + label strong,
        input.has-focus + label strong,
        input + label:hover strong {
            background-color: $blackprimary;
        }

        @media screen and (max-width: 50em) {
        	input + label strong {
        		display: block;
        	}
        }
    }
}

// Form On Submit Validation
.form-validation
{
    padding-top: 30px;
    padding-bottom: 30px;
    @media (min-width: 40em)
    {
        padding-bottom: 92px;
    }
    h2
    {
        line-height: 40px;
        @media (min-width: 1280px)
        {

            line-height: 80px;
        }
    }
    ul
    {
        padding: 0;
        margin: 0;
        li
        {
            background-color: #f3cccb;
            list-style: none;
            font-size: (18 / $base-font-size)+rem;
            line-height: (20 / $base-font-size)+rem;
            @media (min-width: 40em)
            {
                font-size: (18 / $base-font-size)+rem;
                line-height: (13 / $base-font-size)+rem;
            }
            border-radius: 6px;
            width: 100%;
            padding: 10px 14px;
            color: $primary;
            margin-bottom: 14px;
            text-transform: uppercase;
        }
    }
}
.alert
{
    .icon-wrap
    {
        text-align: center;
        @media (min-width: 40em)
        {
            text-align: right;
        }
        .icon-success, .icon-failure
        {
            font-size: 100px;
        }

    }
}

.disclaimer
{
    ul
    {        
        padding-left: 0;
    }
    li
    {
        font-size: 12px;
        line-height: 16px;
        color: #414244;
        margin-bottom: 6px;
        font-family: $font3;
    }
}
