// Footer Styles

.footer-products {
	@media (min-width: 52em) {
		background-color: $white;
	}

	ul {
		padding: 0;
		margin: 0;
	}

	li {
		list-style: none;
		text-align: center;

		&:hover {
			.product-image {
				img {
					transform: scale(1.05);
				}
			}
		}
	}

	.product-image {
		height: 180px;
		padding-top: 30px;
		position: relative;

		img {
			transition: transform 300ms;
			-webkit-transition: transform 300ms;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			transform-origin: bottom;
		}

	}

	.product-name {
		color: $primary;
		font-family: $font1;
		border-bottom: 2px dotted rgba(0, 0, 0, 0.2);
		margin: 52px 15px 0;
		padding-bottom: 20px;
		text-transform: uppercase;

		font-size: (24 / $base-font-size) + rem;
		line-height: (24 / $base-font-size) + rem;
		@media (min-width: 64em) {
			font-size: (28 / $base-font-size) + rem;
			line-height: (28 / $base-font-size) + rem;
		}
	}

	a {
		text-decoration: none;
	}
}

footer {
	position: relative;
	z-index: 3;
	@media (min-width: 52em) {
		// position: absolute;
		// right: 0;
		// bottom: 0;
		// left: 0;
		// z-index: 3;
	}

	.ae-container {
		padding-left: 0;
		padding-right: 0;
	}
}

footer {
	.contact-information {
		margin-top: 1rem;

		@media (min-width: 52em) {
			line-height: 16px;
			text-align: right;
		}
		opacity: 0.5;
		text-align: center;
		line-height: 2;

		address {
			font-size: 12px;
			font-style: normal;
		}

		phone {
			font-size: 12px;
			font-style: normal;
		}

		a {
			color: inherit;
			font-size: 12px;
			font-weight: normal;
			font-style: normal;
		}

		.footer-social {
			padding-top: 18px;
			margin-top: 8px;
		}
	}
}

.footer-wrapper {
	padding-top: 38px;
	padding-bottom: 18px;

	@media (min-width: 52em) {
	}
	a {
		color: white;
		font-size: 1rem;
		text-decoration: none;
	}
}


.footer-logo-wrap {
	a {
		display: block;

		img {
			display: block;
			// margin-top: 26px;
			@media (max-width: 52em) {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

.footer-nav-wrapper {
	.footer-devide-line {
		background-color: $white;
	}

	.footer-nav {
		display: flex;
		justify-content: flex-end;
		a {
			font-family: $font4;
			-webkit-transition: opacity 300ms cubic-bezier(0.785,0.135,0.15,0.86);
			transition: opacity 300ms cubic-bezier(0.785,0.135,0.15,0.86);

			&:hover {
				opacity: .6;
			}
		}
		@media (min-width: 52em) {
			padding-left: 14px;
		}
		@media (max-width: 52em) {
			text-align: center;
			padding: 0;
			flex-wrap: wrap;
			justify-content: center;
		}
		margin: 0;

		.footer-nav-item {
			list-style: none;
			display: inline-block;
			position: relative;
			margin-bottom: 28px;
			a {
				font-size: 20px;
				font-weight: bold;
			}

			@media (max-width: 52em) {
				margin-left: 22px;
				margin-bottom: 16px;
				a {
					font-size: 16px;
					font-weight: normal;
					font-family: $font6;
					&:before
					{
						content: '';
						width: 5px;
						height: 5px;
						background-color: white;
						opacity: 0.3;
						display: block;
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);
						left: -13px;
						border-radius: 100%;
					}
				}
			}
			@media (min-width: 52em) {
				margin-left: 14px;
				a {
					font-size: 14px;
					padding-top: 12px;
					padding-bottom: 12px;
					display: block;
					text-transform: uppercase;
					font-family: $font1;
				}
			}
			@media (min-width: 64em)
			{
				margin-left: 45px;
				a
				{
					font-size: 1rem;
				}
				&:before
				{
					content: '';
					width: 5px;
					height: 5px;
					background-color: white;
					opacity: 0.3;
					display: block;
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);
					left: -23px;
					border-radius: 100%;
				}
			}
		}
	}

	.footer-social {
		@media (min-width: 52em) {
			border-left: 2px solid $white;
		}

		i {
			line-height: 107px;
		}
	}
}

.footer-social {
	@media (min-width: 52em) {
		text-align: right;
	}
	@media (min-width: 75em) {
		padding-left: 14px;
		margin: 0;
		text-align: left;
	}
	margin: 0 auto;
	text-align: center;

	li {
		list-style: none;
		display: inline-block;
	}

	i {
		color: $white;
		font-size: 2em;
	}

	a {
		display: block;
	}
}
@media (max-width: 52em) {
	.footer-products {
		display: inline-block;
		margin-bottom: 48px;
		text-align: center;
		padding: 0 1em;

		.footer-product-item {
			list-style: none;

			a {
				width: 100%;
    			max-width: 80%;
				padding: 10px 0;
     			margin-bottom: 16px;
				font-size: 20px;
				display: inline-block;
				position: relative;
				&:after
				{
					content: '';
					height: 100%;
					width: 100%;
					display: block;
					border: 2px solid rgba(255, 255, 255, 0.5);
					border-left-width: 3px;
					border-right-width: 3px;
					position: absolute;
					left: 0;
					top: 0;
					transform: skewX(-45deg);
					transition: background-color 0.3s ease-in-out;
				}
			}

			&:nth-child(n+3) {
				a {
					border-top: none;
				}
			}

			&:nth-child(even) {
				a {
					border-left: none;
				}
			}
		}
	}
}

.footer-devide-line {
	width: 80%;
	height: 1px;
	background-color: $white;
	margin: 0 auto;
}

.logo-rev {
	display: none;
	position: absolute;
	top: 1rem;
	left: 50%;
	transform: translateX(-50%);

	@media (min-width: 52em) {
		display: block;
	}
	img {
		max-width: 100%;
		width: 175px;
		display: block;
		height: auto;
	}
}

.footer-after {
	position: relative;
	color: $primary;
	background-color: white;

	@media (max-width: 52em) {
		text-align: center;
		background-color: white;
		color: black;
		padding: 26px 0;
	}
	@media (min-width: 52em) {
		padding-bottom: 20px;
	}
	.footer-copyright-wrap
	{
		align-self: flex-end;

		@media (max-width: 52em) {
			padding-bottom: 18px;
		}

		.logo {
			display: block;
			margin: 0 auto 1rem auto;

			img {
				width: 175px;
				display: block;
			}

			@media (min-width: 52em) {
				opacity: 0;
				margin: 1rem auto;
			}
		}
	}
	.footer-copyright {
		opacity: 0.5;
		font-size: 12px;
		width: 100%;
	}

	.footer-legal {
		margin-top: 0;
		margin-bottom: 12px;
		padding: 0;
		width: 100%;
		padding-top: 5px;
		li {
			list-style: none;
			display: inline-block;
			@media (max-width: 52em) {
				text-align: center;
				&:nth-child(n+2) {
					margin-left: 20px;
				}
			}
			@media (min-width: 52em) {
				&:nth-child(n+2) {
					margin-left: 20px;
				}
			}

			a {
				color: inherit;
				opacity: 0.5;
				text-decoration: none;
				-webkit-transition: opacity 300ms cubic-bezier(0.785,0.135,0.15,0.86);
				transition: opacity 300ms cubic-bezier(0.785,0.135,0.15,0.86);
				padding-top: 12px;
				padding-bottom: 12px;
				font-size: 12px;

				&:hover {
					opacity: 1;
				}
			}
		}
	}
}
