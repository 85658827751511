// Gallery Styles

.album-wrapper {
    background-repeat: no-repeat;
    background-size: 130%;
    background-position: center;
    position: relative;
    z-index: 0;
    transition: background-size 300ms;
    -webkit-transition: background-size 300ms;

    .album-info-wrap {
        display: inline-block;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        transition: transform 300ms cubic-bezier(0.785,0.135,0.15,0.86);
        -webkit-transition: transform 300ms cubic-bezier(0.785,0.135,0.15,0.86);
        will-change: transform;
    }


    &:hover {
        cursor: pointer;

        .album-info-wrap {
            -ms-transform: scale(1.05);
            transform: scale(1.05);
            -webkit-filter: blur(0);
            filter: blur(0);
        }
        background-size: 145%;

        &::after {
            opacity: 1;
        }
    }
}

.album-item
{
    &-full
    {
        width: calc(60% - 2px);
        @media(min-width: 52em)
        {
            height: 444px;
        }
        @media(min-width: 64em)
        {
            height: 444px;
        }
        @media(min-width: 75em)
        {
            height: 544px;
        }
    }

    &-half
    {
        width: 40%;
        padding-left: 4px;

        &-item
        {
            display: inline-block;
            width: 100%;
            @media(min-width: 52em)
            {
                height: 220px;
            }
            @media(min-width: 64em)
            {
                height: 220px;
            }
            @media(min-width: 75em)
            {
                height: 270px;
            }
        }
    }
}

.album-information {
    color: $white;
    display: inline-block;
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 50px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 300ms cubic-bezier(0.785,0.135,0.15,0.86);
    -webkit-transition: transform 300ms cubic-bezier(0.785,0.135,0.15,0.86);
    will-change: transform;
    text-align: center;
    // @media (max-width: 1280px)
    // {
    //     padding: 0 88px;
    // }
    // @media (min-width: 1280px)
    // {
    //     padding: 0 28px;
    // }
}

.album-title {
    transition: background-size 300ms;
    -webkit-transition: background-size 300ms;
    color: $white;
    font-family: $font2;
    margin-top: 8px;
    margin-bottom: 0;
    display: inline-block;
    vertical-align: top;
    @media (min-width: 52em) {
        margin-top: 18px;
        font-size: (16 / $base-font-size) + rem;
        line-height: (16 / $base-font-size) + rem;
    }
    @media (min-width: 64em) {
        margin-top: 14px;
        font-size: (26 / $base-font-size) + rem;
        line-height: (26 / $base-font-size) + rem;
    }
    @media (min-width: 85em) {
        font-size: (36 / $base-font-size) + rem;
        line-height: (36 / $base-font-size) + rem;
    }
}

.album-couter-icon {
    font-size: 35px;
    display: inline-block;
    width: 60px;
    height: 40px;
    position: relative;
    margin-top: 6px;

    i {
        position: absolute;
        left: 0;
    }
}

.album-counter-size {
    font-size: 18px;
    font-family: $font5;
    width: 34px;
    position: absolute;
    left: 0;
    margin-top: 13px;
    text-align: center;
    margin-left: 8px;
}

.gallery-lightbox,
.gallery-lightbox-year {
    position: relative;
    display: none;
    width: 90vw;
    height: 700px;
    padding: 50px;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    overflow: auto;
    background: #191818;
}

.gallery-counter {
    position: absolute;
    top: 15px;
}

.gallery-counter {
    color: $white;
}

.gallery-exit {
    position: absolute;
    right: 50px;
    top: 15px;

    .js-gallery-close {
        color: $white;
        font-size: 1.25rem;
        -webkit-transition: opacity .35s cubic-bezier(0.785,0.135,0.15,0.86);
        transition: opacity .35s cubic-bezier(0.785,0.135,0.15,0.86);

        &:hover {
            opacity: .6;
            cursor: pointer;
        }
    }
}

.year-sorting {
    display: block;
    opacity: 1;
    // position: absolute;
    // top: 0;
}

.gallery-second {
    display: none;
    width: 100%;
}

.carousel-gallery {
    margin-bottom: 40px;

    .flickity-viewport {
        // margin: 0 100px;
    }

    .carousel-cell {
        width: 100%;
        height: 450px;
        margin-right: 10px;
        border-radius: 5px;
        text-align: center;
        overflow: hidden;
    }

    .flickity-prev-next-button {
        width: 64px;
        height: 64px;
        background: transparent!important;

        .arrow {
            fill: $primary!important;
        }
    }
}

.carousel-gallery-nav {
    .flickity-viewport {
        height: 100px;
    }
}

.carousel-gallery-nav .carousel-cell {
    height: auto;
    width: auto;
    border: 2px solid $blackprimary;
    display: block;

    img {
        display: block;
    }
}

.carousel-gallery-nav .carousel-cell:before {
    font-size: 50px;
    line-height: 80px;
}

.carousel-gallery-nav .carousel-cell.is-nav-selected {
    border: 2px solid $primary;
}

.gallery-lightbox-year {
    h2,
    h4 {
        color: white;
        text-transform: uppercase;
    }

    .year-grid-info {
        text-align: center;
        margin-bottom: 48px;
    }

    .gallery-second {
        .gallery-previous {
            cursor: pointer;
            color: white;
            transition: opacity .2s ease-out;

            &:hover {
                opacity: .8;
            }
        }
    }
}

.year-grid-item {
    text-align: center;
    padding: 0 28px;
    margin-bottom: 48px;

    > span {
        width: 100%;
    }
}

.gallery-buttons
{
    padding-top: 58px;
    padding-bottom: 58px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.gallery-button
{
    height: 30px;
    padding: 0 30px;
    position: relative;
    display: inline-block;
    margin-left: -2px;
    &::before
    {
        content: '';
        height: 100%;
        width: 100%;
        display: block;
        background-color: white;
        border: 2px solid $primary;
        border-left-width: 3px;
        border-right-width: 3px;
        position: absolute;
        left: 0;
        top: 0;
        transform: skewX(-45deg);
        transition: background-color 0.3s ease-in-out;
    }
    span,input
    {
        font-size: (16 / $base-font-size) + rem;
        line-height: 30px;
        text-transform: uppercase;
        color: $primary;
        font-family: $font5;
        position: relative;
        transition: color 0.3s ease-in-out;
        -webkit-appearance: none;
        background: none;
        border: none;
        height: 100%;
    }
    &:hover
    {
        cursor: pointer;
        &::before
        {
            background-color:$primary;
        }
        span,
        input
        {
            color: white;
        }
    }

    @media (min-width: 64em)
    {
        height: 40px;
        padding: 0 50px;
        span
        {
            font-size: (18 / $base-font-size) + rem;
            line-height: 40px;
        }
    }
}

.gallery-section
{
    .home-section-title
    {
        position: relative;
        text-align: right;
        &::before
        {
            content: '';
            display: block;
            position: absolute;
            bottom: -15px;
            right: 0;
            width: 105px;
            height: 5px;
            background-image: url(/images/wave-gray.svg);
            background-repeat: repeat-x;
            background-position: 0, 0;
            background-size: 12px 100%;
        }
    }
}
