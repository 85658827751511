// Typographic Styles. Trying to change things into styles by classes and not overwritten by master class.

p
{
    font-size: 1rem;
    margin-top: 0.2em;
    @media (min-width: 40.05em)
    {
        font-size: (18 / $base-font-size) + rem;
    }
}


h1.page-title
{
    @media (min-width: 40.05em)
    {
        margin-top: 70px;
        font-size: (60 / $base-font-size) + rem;
        line-height: (52 / $base-font-size) + rem;
    }
    z-index: 2;
    position: relative;
    text-align: left;
    margin-top: 140px;
    color: $white;
    font-family: $font1;
    text-transform: uppercase;
    font-size: (44 / $base-font-size) + rem;
    line-height: (36 / $base-font-size) + rem;
}
h2
{
    text-transform: uppercase;
    font-size: (32 / $base-font-size) + rem;
    line-height: (32 / $base-font-size) + rem;
    letter-spacing: -1px;
    color: $blackprimary;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
h3
{
    text-transform: uppercase;
    font-size: (22 / $base-font-size) + rem;
    line-height: (22 / $base-font-size) + rem;
    color: $blackprimary;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}
ul
{
    li
    {
        // list-style: none;
    }
}
a
{
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.basic-content
{
    ul.no-padding
    {
        padding: 0;
    }

    p
    {
        line-height: 28px;
        &:first-child
        {
            margin-top: 0;
        }
    }
    a
    {
        color: $blackprimary;
        text-decoration: none;
        position: relative;
        line-height: 28px;
        &.link-icon{
            display: inline-block;
            margin-left: 28px;
        }
        &.link-icon::before
        {
            content: '\e80d';
            font-family: "toyota-bonus-bucks";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: block;
            text-decoration: inherit;
            width: 1em;
            margin-right: 10px;
            text-align: center;
            position: absolute;
            left: -26px;
        }
        &::after
        {
            content: '';
            width: 100%;
            height: 2px;
            bottom: -2px;
            left: 0;
            position: absolute;
            background-color: $blackprimary;
        }
    }

}

.primary-text
{
    color: $primary;
}

.secondary-text
{
    color: $blackprimary;
}
