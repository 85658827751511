body
{
}


.ae-container
{
    @media (max-width: 40em)
    {
        padding-left: 1.25em;
        padding-right: 1.25em;
    }
}
