// Footer Styles
.btn
{
	position: relative;
	font-family: $font5;
	font-size: (18 / $base-font-size)+rem;
	line-height: (14 / $base-font-size)+rem;
	padding: 13px 0;
	color: white;
	text-transform: uppercase;
	font-weight: normal;
	display: inline-block;
	text-decoration: none;
	// -webkit-transition: background-color 0.35s cubic-bezier(0.785,0.135,0.15,0.86),
	transition: background-color 0.35s cubic-bezier(0.785,0.135,0.15,0.86),
	border-color 0.35s cubic-bezier(0.785,0.135,0.15,0.86) ;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	.btn-text
	{
		display: inline-block;
		transition: transform 300ms cubic-bezier(0.785,0.135,0.15,0.86);
		-webkit-transition: transform 300ms cubic-bezier(0.785,0.135,0.15,0.86);
	}
	&__blue
	{
		background-color: $primary;
		color:$primary;
		span
		{
			color: white;
		}
	}
	&__white
	{
		background-color: white;
		color: white;
		span
		{
			color: $primary;
		}
		// &:hover
		// {
		// 	background-color: $blackprimary;
		// }
	}
}


// Dropdown menu
/* Style The Dropdown Button */
.dropbtn {
    background-color: $primary;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
	@media (min-width: 64em)
	{
	  display: none;
	}
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
	@media (max-width: 64em)
	{
    // position: relative;
    display: inline-block;
  	}
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
	left: 0;
	margin-top: 0;
	text-align: left;
	@media (max-width: 64em)
	{
	padding-top: 24px;
	padding-bottom: 24px;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
	width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  	}
}

/* Links inside the dropdown */
.dropdown-content a {
	@media (max-width: 64em)
	{
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
	min-width: 320px;
	}
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}
@media (min-width: 52em)
{


	/* Change the background color of the dropdown button when the dropdown content is shown */
	.dropdown:hover .dropbtn {
	    background-color: $primary;
	}
}
