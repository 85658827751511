// Global Variables
$base-font-size: 16;

$font1: 'CustomOpenSans ExtraBoldItalic', 'Arial', 'Sans-Serif';
$font2: 'CustomOpenSans ExtraBold', 'Arial', 'Sans-Serif';
$font3: 'CustomOpenSans SemiBold', 'Arial', 'Sans-Serif';
$font4: 'CustomOpenSans BoldItalic', 'Arial', 'Sans-Serif';
$font5: 'CustomOpenSans Bold', Arial, sans-serif;
$font6: 'CustomOpenSans Regular', Arial, sans-serif;

$blue: #064c7f;
