// Homepage Styles

// Section Title
.home-section-title-wrapper {
  padding-top: 58px;
  padding-bottom: 58px;
}
.home-section-title {
  color: $primary;
  font-family: $font1;
  font-size: (36 / $base-font-size) + rem;
}

.home-slider {
  position: relative;
  z-index: 0;
}

.home-spacer {
  height: 2px;
  background: $blue;
}

.home-winners {
  width: 1600px;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 2px;
  padding-left: 20px;

  @media only screen and (max-width: 87.5em) {
    width: 100%;
    padding: 0;
  }

  &__title {
    padding: 35px 30px 30px 10px;
    margin: 0;
    text-align: left;
    color: $blue;
    font-weight: 400;
    font-size: (32 / $base-font-size) + rem;
    line-height: (32 / $base-font-size) + rem;
    font-family: $font6;

    @media only screen and (max-width: 87.5em) {
      padding-left: 20px;
      width: 100%;
      text-align: center;
    }

    @media only screen and (max-width: 28.125em) {
      font-size: (24 / $base-font-size) + rem;
      line-height: (24 / $base-font-size) + rem;
    }

    &--bold {
      font-family: $font2;
      font-weight: bold;
    }
  }

  &__slider {
    display: flex;
    padding: 30px 0 30px 250px;
    background-image: linear-gradient(45deg, #fff 7%, $blue 7%);
    color: #fff;
    text-transform: uppercase;

    @media only screen and (max-width: 87.5em) {
      flex-direction: column;
      text-align: center;
      padding: 30px;
      background: $blue;
    }
  }

  &__slider-title {
    font-size: (24 / $base-font-size) + rem;
    line-height: (24 / $base-font-size) + rem;

    @media only screen and (max-width: 87.5em) {
      margin-bottom: 20px;
    }
  }

  &__slider-body {
    flex: 1;
    margin-left: 20px;

    @media only screen and (max-width: 87.5em) {
      margin-left: 0;
    }
  }

  &__winner {
    width: 100%;
    overflow: hidden;
  }
}

.winner {
  &__content {
    font-size: (24 / $base-font-size) + rem;

    @media only screen and (max-width: 28.125em) {
      font-size: (16 / $base-font-size) + rem;
      line-height: (16 / $base-font-size) + rem;
    }
  }

  &__tourny {
    font-size: (18 / $base-font-size) + rem;

    @media only screen and (max-width: 28.125em) {
      font-size: (14 / $base-font-size) + rem;
      line-height: (14 / $base-font-size) + rem;
    }
  }
}

// Slider
.carousel-main,
.carousel-college-main {
  .carousel-cell {
    overflow: hidden;
  }

  /* Disable Flickity by default */
  &:after {
    content: ' ';
    display: none; /* hide :after */
  }

  @media screen and (min-width: 52em) {
    /* Enable Flickity for large devices */
    &:after {
      content: 'flickity';
    }
  }

  max-height: 720px;
  .flickity-viewport {
    // margin: 0 100px;
  }
  // /* Fade CSS */
  // .flickity-slider {
  //   transform: none !important;
  // }
  // .carousel-cell {
  //   left: 0 !important;
  //   opacity: 0;
  //   transition: opacity 0.3s ease-in-out;
  //   z-index: -1;
  // }
  // .is-selected {
  //   opacity: 1;
  //   z-index: 1;
  // }

  .flickity-page-dots {
    bottom: 25px;
    z-index: 10;
    .dot {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      border: 1px solid white;
      opacity: 1;
      &.is-selected {
        border-color: white;
        background-color: white;
      }
    }
  }

  .ae-container {
    height: 100%;
    position: relative;
  }
  .slider-content {
    position: absolute;
    z-index: 3;
    bottom: 92px;
  }

  .slide-sub-title {
    color: white;
    font-size: (24 / $base-font-size) + rem;
    line-height: (24 / $base-font-size) + rem;
    font-weight: 400;
    margin-top: 0;
  }

  .slide-cta {
    margin-top: 32px;
  }

  .carousel-cell {
    width: 100%;
    height: 720px;
    background: $blackprimary;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .college & .college-series {
    background-position: 0px 25%;
  }

  .register-truck-two {
    // background-position: 40%;
  }

  /* cell overlay */
  // .carousel-cell:before {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 1;
  //   opacity: 0.6;
  //   background-image: -webkit-linear-gradient(#000000 50%, #000000 70%, #000000 100%);
  //   background-image: -o-linear-gradient(#000000 50%, #000000 70%, #000000 100%);
  //   background-image: linear-gradient(#000000 50%, #000000 70%, #000000 100%);
  // }
}

.slider-content {
  .slide-title {
    color: white;
    font-size: (50 / $base-font-size) + rem;
    line-height: (50 / $base-font-size) + rem;
    font-family: $font1;
    margin-bottom: 8px;
  }
  i {
    color: inherit;
  }
  i,
  i::before {
    line-height: 0.8rem;
    max-height: 0.8rem;
    width: 0.8rem;
  }
  i::before {
    font-size: 1.5rem;
    vertical-align: bottom;
  }
  &.slider-content-right {
    text-align: right;
    right: 0;
    .btn-offscreen {
      padding-left: 20px;
      border-bottom-left-radius: 3px;
      @media (max-width: 52em) {
        padding-right: 40px;
      }
      &::after {
        content: '';
        border-right: 40px solid;
        left: -39px;
        border-right-color: inherit;
      }
      &::before {
        right: -1000px;
      }
      &:hover {
        transform: translateX(10px);
        .btn-text {
          transform: translateX(20px);
        }
      }
    }
  }
  &.slider-content-left {
    text-align: left;
    left: 0;
    .btn-offscreen {
      padding-right: 20px;
      border-bottom-right-radius: 3px;
      &::after {
        content: '';
        border-left: 40px solid;
        right: -39px;
        border-left-color: inherit;
      }
      &::before {
        left: -1000px;
      }
      &:hover {
        transform: translateX(10px);
        .btn-text {
          transform: translateX(20px);
        }
      }
    }
  }
  .btn-offscreen {
    transition: transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transition: transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    &::after {
      content: '';
      display: block;
      border-top: 0;
      border-bottom: 40px solid transparent;
      position: absolute;
      bottom: 0;
    }
    &::before {
      content: '';
      display: block;
      width: 1000px;
      position: absolute;
      bottom: 0;
      height: 40px;
      background-color: inherit;
    }
  }
}

.mobile-header-wrapper {
  background-image: url('../images/slide-bg.jpg');
  padding-top: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 76px;
  overflow: hidden;

  @media (min-width: 40em) {
    .home-section-title {
      font-size: (56 / $base-font-size) + rem;
      line-height: (56 / $base-font-size) + rem;
    }
  }
}

.mobile-sub-nav {
  padding: 40px 0;
  background-color: white;
  text-align: center;
  a {
    width: 100%;
    max-width: 80%;
    margin-bottom: 16px;
    color: $primary;
    &:after {
      content: '';
      height: 100%;
      width: 100%;
      display: block;
      border: 2px solid $primary;
      border-left-width: 3px;
      border-right-width: 3px;
      position: absolute;
      left: 0;
      top: 0;
      transform: skewX(-45deg);
      transition: background-color 0.3s ease-in-out;
    }
    @media (min-width: 64em) {
      width: 48%;
      &:nth-child(odd) {
        margin-right: 2%;
      }
    }
  }
}

.home-featured {
  background-color: $lightgray;
  overflow: hidden;

  .featured-item {
    height: 120px;
    background-color: $primary;
    position: relative;
    display: block;
    z-index: 5;
    border-bottom: 2px solid white;
    // overflow: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    transition: transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transition: transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    will-change: transform;
    text-align: center;
    @supports (mix-blend-mode: multiply) {
      mix-blend-mode: multiply;
    }

    &.featured-two {
      .featured-title-wrapper {
        width: calc(50% + 120px);
      }
      .featured-background {
        width: calc(50% - 40px);
      }
    }
    &.featured-three {
      .featured-title-wrapper {
        width: calc(50% + 240px);
      }
      .featured-background {
        width: calc(50% - 160px);
      }
    }
    &.featured-three {
      border-bottom: none;
      .featured-background {
        border-bottom: none;
      }
      .featured-title-wrapper {
        border-bottom: none;
        &::before {
          border-bottom: none;
        }
        &::after {
          border-bottom: none;
        }
      }
    }
    span {
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
    }
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 6;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.6;
      // background: #000000;
      -webkit-transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 6;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      background-color: black;
      @supports (mix-blend-mode: saturation) {
        mix-blend-mode: saturation;
      }

      -webkit-transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
  }

  .featured-background {
    height: 120px;
    width: calc(50% + 80px);
    background-size: auto 100%;
    background-position: right 0;
    background-repeat: no-repeat;
    transition: transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    border-bottom: 2px solid white;
  }

  a {
    text-decoration: none;
    &:hover {
      .featured-item {
        &::after {
          opacity: 0;
        }
        &::before {
          opacity: 1;
        }
      }
      .featured-title {
        &::before {
          animation-play-state: running;
        }
        i {
          transform: translateX(60px);
        }
      }
      .featured-title-wrapper {
        transform: translateX(60px);
      }
      .featured-background {
        transform: translateX(-20px);
      }
    }
  }
  .featured-title-wrapper {
    height: 120px;
    width: 50%;
    background-color: white;
    position: absolute;
    right: 0;
    z-index: 10;
    text-align: left;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    transition: transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transition: transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    will-change: transform;
    border-bottom: 2px solid #d8d8d8;

    &::before {
      content: '';
      display: block;
      border-top: 120px solid transparent;
      border-right: 120px solid white;
      position: absolute;
      left: -120px;
      bottom: 0;
    }
    &::after {
      content: '';
      position: absolute;
      width: 120px;
      left: -120px;
      bottom: -2px;
      border-bottom: 2px solid #d8d8d8;
    }
  }
  .featured-title {
    position: relative;
    left: 0;
    color: $primary;
    text-transform: uppercase;
    font-family: $font1;
    padding: 0 18px;
    height: 120px;
    line-height: (120 / $base-font-size) + rem;
    font-size: (25 / $base-font-size) + rem;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    transition: transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transition: transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    will-change: transform;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -5px;
      left: 18px;
      width: 105px;
      height: 5px;
      background-image: url(/images/wave-gray.svg);
      background-repeat: repeat-x;
      background-position: 0, 0;
      background-size: 12px 100%;
      animation: waveScroll 5s linear infinite;
      animation-play-state: paused;
    }

    i {
      display: inline-block;
      transition: transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }

    .college & {
      @media (min-width: 64em) {
        top: 28%;
        font-size: (36 / $base-font-size) + rem;
        line-height: (36 / $base-font-size) + rem;
      }
    }
    .general & {
      @media (min-width: 64em) {
        font-size: (36 / $base-font-size) + rem;
      }
    }
  }
}

// Events
.events.home-events {
  padding-top: 58px;
  padding-bottom: 58px;
  overflow: hidden;
  @media (min-width: 52em) {
    padding-top: 78px;
    padding-bottom: 78px;
  }
  background-color: $primary;
  h2 {
    color: $white;
    font-family: $font1;
    position: relative;
    font-size: (50 / $base-font-size) + rem;
    line-height: (50 / $base-font-size) + rem;
    text-align: center;
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      width: 105px;
      height: 5px;
      background-image: url(/images/wave-white.svg);
      background-repeat: repeat-x;
      background-position: 0, 0;
      background-size: 12px 100%;
    }
    @media (min-width: 64em) {
      font-size: (40 / $base-font-size) + rem;
      line-height: (40 / $base-font-size) + rem;
      text-align: left;
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -15px;
        transform: translateX(0);
        left: 0;
        width: 105px;
        height: 5px;
        background-image: url(/images/wave-white.svg);
        background-repeat: repeat-x;
        background-position: 0, 0;
        background-size: 12px 100%;
      }
    }
  }
  h4 {
    color: $white;
  }

  .events-wrapper {
    margin-bottom: 18px;
  }

  .events-list--header {
    border-bottom: 2px dotted rgba(255, 255, 255, 0.2);
    padding: 0 10px;
    h4 {
      color: $white;
      font-weight: 300;
      font-family: $font4;
    }
  }
  .events-list {
    border-bottom: 2px dotted rgba(255, 255, 255, 0.2);
    @media (max-width: 52em) {
      margin-bottom: 56px;
      &:nth-child(n + 5) {
        display: none;
      }
    }
  }
  // i
  // {
  //   color: $white;
  // }
}

// Video
.home-video {
  &.home-ethics {
    .ae-grid {
      flex-direction: row;
    }
    .video-title {
      text-align: left;
      line-height: 1em;

      small {
        font-style: normal;
        display: block;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1rem;
        margin: 1rem 0 2rem;
        padding: 0;
      }
      // @media (max-width: 40em) {
      //
      //     small {
      //
      //     }
      // }
    }
    @media (max-width: 40em) {
      padding: 100px 0;
      background-position: left bottom;
      background-image: url('../images/ethics-mobile-bg.jpg');
    }
    @media (min-width: 40em) {
      background-image: url('../images/ethics-background.jpg');
    }
  }

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0 400px;
  overflow: hidden;

  @media (max-width: 40em) {
    background-image: url('../images/video-mobile-bg.jpg');
  }
  @media (min-width: 40em) {
    background-image: url('../images/video-background.jpg');
    padding: 100px 0;
  }
  @media (min-width: 52em) {
    padding: 200px 0;
  }
  .ae-grid {
    flex-direction: row-reverse;
  }
  .video-title {
    text-align: right;
    color: $white;
    width: 100%;
    font-family: $font1;
    font-size: (50 / $base-font-size) + rem;
    line-height: (50 / $base-font-size) + rem;
    @media (min-width: 52em) {
      font-size: (60 / $base-font-size) + rem;
      line-height: (60 / $base-font-size) + rem;
    }
  }
  i {
    border: 2px solid white;
    padding: 0;
    border-radius: 100%;
    margin-left: 8px;
    &::before {
      padding-left: 3px;
    }
  }
}

// Infinite wave animation
@keyframes waveScroll {
  0% {
    background-position: 0, 0;
  }
  100% {
    background-position: 105px, 0;
  }
}
