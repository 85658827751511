// Main Bonus Bucks color changes. Everything related Theme color is stored here.

// Header Color Style
header
{
	.logo-container
	{
	}
}

// Footer Color Style
.footer
{
	background-color: $primary;
}

.footer-toyota-trucks
{
	.truck-logo-color
	{
		fill: $primary;
	}
}

// Homepage Styles
// Team Toyota
.team-toyota
{
  .member-info
  {
    &::before
    {
      background-color: $primary;
    }
	@media (min-width: 52em)
	{
	  &:hover
	  {
		&::before
		{
		  opacity: 0.6;
		}
	  }
	}
   }
}
