/**
 * Styles for the "General" section of the site
 */

@import "mixins/forms";

@import "partials/variables";
@import "partials/main/variables";
@import "partials/main/colors";
@import "partials/global";
@import "partials/header";
@import "partials/footer";
@import "partials/buttons";
@import "partials/gallery";
@import "partials/registration";
@import "partials/typography";
@import "partials/utilities";
@import "partials/home";
@import "partials/products";
@import "partials/events";

@import "partials/basic";

@import "libs/flickity";
@import "libs/lity";
