// Events Styles

.events-nav {
	@media (max-width: 64em) {
		text-align: center;
		width: 100%;

	}
}

.events-navigation {
	// color: $blue;
	@media (min-width: 64em) {
		color: white;
	}

	li {
		list-style: none;
		@media (min-width: 64em) {
			margin-bottom: 20px;
		}
		position: relative;

		a {
			border-bottom: none;
			display: block;
			padding-right: 38;

			&::after {
				display: none;
			}

			&.active {
                @media (max-width: 64em) {
                    color: $blue;
    			}
				&::before {
					opacity: 1;
					content: '';
					width: 3px;
					height: 100%;
					position: absolute;
					left: -14px;
					top: 0;
					background-color: $primary;
				}
			}
			@media (min-width: 52em) {
				&:hover {
					&::before {
						content: '';
						width: 3px;
						height: 100%;
						position: absolute;
						left: -14px;
						top: 0;
						background-color: $primary;
						opacity: 0.25;
					}
				}
			}
		}
	}
}

.events-content {
	padding-left: 28px;
	padding-right: 28px;
	@media (min-width: 52em) {
		padding-left: 48px;
		padding-right: 48px;
	}

	& > h4 {

		@media (max-width: 64em) {
			color: white;
		}
		font-family: $font5;
		margin-bottom: 6px;
		margin-top: 2.5rem;
	}
}

// Events
// .events-list--row
// {
//   height: 50px;
// }

.events {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	@media (max-width: 64em) {
		color: white;
	}

	h2 {
		margin-top: 3rem;

		@media (max-width: 64em) {
			color: white;
		}
		font-family: $font5;

		&:first-child {
			margin-top: 0;
		}
	}

	h3 {
		margin-top: 2rem;
		font-family: $font5;
		@media (max-width: 64em) {
			color: white;
		}
	}

	h3 + h4 {
		margin-top: 1rem;
	}

	.events-list--header {
		h4 {
			margin-bottom: 10px;
			padding: 0 28px 0 0;
			font-size: (16 / $base-font-size) + rem;
			text-transform: uppercase;
			color: $gray;
			font-weight: 400;
		}

		border-bottom: 1px solid $gray;
	}

	.events--header {
		margin-bottom: 32px;
	}

	.events-list {
		border-bottom: 1px solid $gray;
		@media (max-width: 52em) {
			margin-bottom: 56px;
		}

		h4 {
			padding: 0 18px 0 0;
			@media (max-width: 52em) {
				margin: 14px 0;
				width: 100%;
				padding-left: 10px;
				font-size: (14 / $base-font-size) + rem;
				line-height: (14 / $base-font-size) + rem;
			}
			@media (min-width: 52em) {
				height: 40px;
				display: table-cell;
				vertical-align: middle;
				font-size: (14 / $base-font-size) + rem;
				line-height: (14 / $base-font-size) + rem;
			}
			// font-size: (14 / $base-font-size) + rem;
			// text-transform: uppercase;
		}
	}

	// i
	// {
	//   font-size: 32px;
	//   color: white;
	//   display: inline-block;
	//   &.icon-location
	//   {
	//     font-size: 40px;
	//   }
	//   &::before
	//   {
	//     width: 40px;
	//     text-align: left;
	//   }
	// }
	.events-list--name {
		font-size: (16 / $base-font-size) + rem;
		line-height: (16 / $base-font-size) + rem;
		font-weight: normal;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 6px!important;
		font-family: $font4;
		@media (min-width: 52em) {
			margin-bottom: 20px;
			text-transform: initial;
			font-weight: normal;
			font-size: (14 / $base-font-size) + rem;
			line-height: (14 / $base-font-size) + rem;
			padding-right: 18px;
		}
	}

	.events-list--location {
		font-weight: 300;
		display: inline-block;
		font-size: (14 / $base-font-size) + rem;
		line-height: (14 / $base-font-size) + rem;
		letter-spacing: -0.2px;
	}

	.events-list--start-date {
		font-weight: 300;
	}

	.events-list--devider {
		font-weight: 300;
		padding-left: 10px;
		padding-right: 10px;
		@media (min-width: 52em) {
			// padding-left: 70%;
			padding-right: 0;
		}
	}

	.events-list--end-date {
		font-weight: 300;
	}

	.icon-location {
		margin-left: 8px;
		margin-top: 8px;
		vertical-align: middle;
		background-image: url("../images/location.png");
		display: inline-block;
		text-decoration: inherit;
		width: 18px;
		height: 25px;
		margin-right: 0.2em;
		text-align: center;
		/* opacity: .8; */
		font-variant: normal;
		text-transform: none;
		line-height: 1em;
		/* font-size: 120%; */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
	}

	.icon-date {
		margin-top: 8px;
		vertical-align: middle;
		background-image: url("../images/date.png");
		display: inline-block;
		text-decoration: inherit;
		width: 24px;
		height: 25px;
		margin-right: 0.2em;
		text-align: center;
		/* opacity: .8; */
		font-variant: normal;
		text-transform: none;
		line-height: 1em;
		margin-left: 0.2em;
		/* font-size: 120%; */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
	}
}
