// Basic page Components
html
{
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
// Basic page header background
.basic-header
{
    width: 100%;
    height: 600px;
    @media (min-width: 40em)
    {
        height: 400px;
    }
    @media (min-width: 52em)
    {
        height: 450px;
    }
    position: absolute;
    overflow: hidden;
    z-index: 1;
    .basic-header--background
        {
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 1;
            background: -webkit-linear-gradient($primary, rgb(18, 18, 18));
            background: -o-linear-gradient($primary, rgb(18, 18, 18));
            background: linear-gradient($primary, rgb(18, 18, 18));

            img {
                @media (min-width: 768px) {
                    bottom: -25%;
                }

                transform: translateX(-50%);
                left: 50%;
                position: absolute;
                -webkit-filter: blur(5px);
                filter: blur(5px);
                transition: filter 400ms, -webkit-filter 400ms;
                    width: 100%;
                    height: auto;
                &.lazyloaded {
                    -webkit-filter: blur(0);
                    filter: blur(0);
                }
            }
        }
}
// Main Content
.basic-wrapper
{
    position: relative;
    z-index: 3;
    @media (max-width: 75em) and (min-width: 1295px)
    {
        padding: 0 48px;
    }
    margin: 0 auto;
    padding-top: 100px;
    @media (min-width: 64em)
    {
        padding-top: 250px;
        padding-bottom: 88px;
    }
    .page-header
    {
        position: relative;
    }
    .basic-content
    {
        background-color: $white;

        @media (max-width: 64em)
        {
            &.events-wrapper
            {
            background-color: #064c7f;
            }
        }
    }
    .content-padding
    {
        padding-top: 22px;
        padding-bottom: 22px;
        @media (min-width: 40.05em)
        {
            padding-top: 62px;
            padding-bottom: 62px;
        }
    }
}

.error-title
{
    text-align: center;
    color: $white;
    opacity: 0.25;
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    font-size: (218 / $base-font-size)+rem;
    @media (min-width: 40.05em)
    {
        top: 40px;
        font-size: (318 / $base-font-size)+rem;
    }
}
.links-list
{
    @media (min-width: 56em)
    {
        li
        {
            display: inline-block;
        }
        li:not(:first-of-type)
        {
            margin-left: 48px;
        }
    }
    li
    {
        margin-top: 18px;
        margin-bottom: 18px;
    }
    a
    {
        font-family: $font3;
        text-transform: uppercase;
    }

}

.registration-contact
{
    h4
    {
        font-family: $font2;
        text-transform: uppercase;
        margin-bottom: 8px;
        font-size: 18px;
        color: #414244;
    }
    p,
    address,
    phone,
    a
    {
        font-family: $font3;
        color: #414244;
        font-size: 16px;
        line-height: 18px;
    }
    a::after
    {
        display: none;
    }

    @media (min-width: 52em)
    {
        width: 380px;
    }


}

.ethics {
    max-width: 700px;
    margin: 0 auto;
    font-size: 1.1em;
    li {
        margin-bottom: 1.5em;
        line-height: 1.3em;
    }
    // li {
    //     display: inline-block;
    //     margin-bottom: 1.5em;
    // }
}
