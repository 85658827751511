// Header & Navigation Component


header
{
	position: absolute;
	width: 100%;
	z-index: 10;


	.ae-container
	{
		padding-left: 0;
		padding-right: 0;
	}

	@media (max-width: 40em)
	{
		padding-left: 0;
		padding-right: 0;
		// position: relative;
	}
	// Logo
	.logo-container
	{
		background-color: black;
		.general &
		{
			background-color: transparent;
		}
		display: inline-block;
		margin: 0;
		vertical-align: middle;
		padding: 22px 34px;

		@media (max-width: 40em)
		{
			width: 100%;
		}
		@media (min-width: 64rem)
		{
			padding: 22px 34px;
			margin: 0 34px;
		}
		& > a
		{
			display: block;
		}
	}
	.logo
	{
		display: block;
		@media (max-width: 40em)
		{
			margin: 0 auto;
		}
	}


	// Overall Wrap
	.nav-wrapper
	{
		display: inline-block;
		width: 100%;
		text-align: center;
	}

	// Navigation
	.main-nav
	{
		padding: 0;
		vertical-align: middle;
		@media (min-width: 40.05em)
		{
			display: inline-block;
	    	margin: 0;
		}
		@media (max-width: 40em)
	    {
			margin: 0 auto;
			padding: 0;
			width: auto;
			text-align: center;
			padding: 0 18px;
	    }

		.mobile-first-link {
			@media (max-width: 40em) {
				margin-left: 0;
			}
		}

		& .nav-item
		{



			list-style: none;
			display: inline-block;
			margin-left: 20px;
			position: relative;

			@media (min-width: 64em)
			{
				margin-left: 38px;
				&:before
				{
					content: '';
					width: 5px;
					height: 5px;
					background-color: white;
					opacity: 0.3;
					display: block;
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);
					left: -23px;
					border-radius: 100%;
				}
			}
			&:nth-child(1)
			{
				margin-left: 0;
				&:before
				{
					content: none;
				}
			}

			a {
				color: $white;
				text-decoration: none;
				font-family: $font1;
				@media (min-width: 40.05em)
				{
					line-height: 100px;
					height: 100px;
				}
				font-size: 12px;
				line-height: 72px;
				height: 72px;
				text-transform: uppercase;
				position: relative;
				padding-bottom: 10px;
				letter-spacing: 1px;
				@media (max-width: 40em) {
					line-height: 4em;
					height: 4em;
				}
				@media (min-width: 64em)
				{
					font-size: 16px;
				}
				&:before
				{
					content: '';
					width: 100%;
					height: 0;
					background-color: white;
					position: absolute;
					top: 23px;
					transition: height 150ms ease-out;
					transform: skewx(-15deg) translateX(-1px);
				}

				&.active {
					opacity: 1;
					&:before
					{
						content: '';
						width: 100%;
						height: 6px;
						background-color: white;
						position: absolute;
						bottom: -8px;
					}
				}
				&:hover
				{
					&:before
					{
						height: 3px;
					}
				}
			}
		}
	}

}
